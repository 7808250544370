import { InternalAxiosRequestConfig } from 'axios'
import { Tokens } from 'config'

export const checkIsLocalhost = () => {
	const HOST = 'localhost'
	return document.location.hostname === HOST
}

export const setHeaderAuth = (
	request: InternalAxiosRequestConfig<any>
): void => {
	const { access_token } = Tokens.get()

	if (access_token) {
		request.headers.Authorization = `Bearer ${access_token}`
	}
}
