import appDefaultPermissions from './appDefault'
import tenantDefaultPermissions from './tenants/default'
import { Permissions } from './type'

const tenantPermissions: {
	[key: string]: Permissions
} = {
	DEFAULT: appDefaultPermissions,
	SINERGIA: tenantDefaultPermissions,
	ANIMA: tenantDefaultPermissions,
	INSTITUTOANIMA: tenantDefaultPermissions,
	TDS: tenantDefaultPermissions,
	FDC: tenantDefaultPermissions,
	MAGALU: tenantDefaultPermissions,
	MGC: tenantDefaultPermissions,
	CIADETALENTOS: tenantDefaultPermissions,
	JNJ: tenantDefaultPermissions
,
	UMUARAMA2050: tenantDefaultPermissions,
	EQUATORIAL: tenantDefaultPermissions,
	RVDIGITAL: tenantDefaultPermissions,
	PCR: tenantDefaultPermissions,
	LUMINA: tenantDefaultPermissions,
	CGEE: tenantDefaultPermissions,
	BATERIASMOURA: tenantDefaultPermissions,
	SAEB: tenantDefaultPermissions,
	SEBRAEPB: tenantDefaultPermissions,
	CHILDHOOD: tenantDefaultPermissions,
	FARIASBRITO: tenantDefaultPermissions,
	LUMOSACADEMY: tenantDefaultPermissions,
	MITSLOANREVIEW: tenantDefaultPermissions,
	AMBEV: tenantDefaultPermissions,
	FUNDACAOITAU: tenantDefaultPermissions,
	PORTOMARINHO: tenantDefaultPermissions,
	AMIL: tenantDefaultPermissions,
	DEEPESG: tenantDefaultPermissions,
	HGS: tenantDefaultPermissions,
	GOOGLECLOUD: tenantDefaultPermissions,
	SINAENCO: tenantDefaultPermissions,
	BB: tenantDefaultPermissions,
	TRESCORACOES: tenantDefaultPermissions,
	PORTODIGITAL: tenantDefaultPermissions,
	INOVABRA: tenantDefaultPermissions,
	EFESIMPACT: tenantDefaultPermissions
}

export default tenantPermissions
