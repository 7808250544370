import { ThemeColors } from '../types'
import tdsCustomColors from './colors'

const themeColors: ThemeColors = {
	primary: {
		dark: '#18BAA8',
		light: '#000000'
	},

	secondary: {
		dark: '#003399',
		light: '#9FAEE5'
	}
}

export default themeColors
