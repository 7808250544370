import { FC } from 'react'
import ReactMarkdown, { Components } from 'react-markdown'

import {
	Text,
	Box,
	UnorderedList,
	OrderedList,
	ListItem,
	Image
} from '@chakra-ui/react'

import remarkGfm from 'remark-gfm'

import Hyperlink from '../hyperlink'

interface MarkdownContentProps {
	content: string
	fontSize?: string | Record<string, string>
}

const MarkdownComponents: Components = {
	p: ({ children }) => <Text mb={2}>{children}</Text>,
	h1: ({ children }) => (
		<Text fontSize='xl' fontWeight='bold' mb={2}>
			{children}
		</Text>
	),
	h2: ({ children }) => (
		<Text fontSize='lg' fontWeight='bold' mb={2}>
			{children}
		</Text>
	),
	h3: ({ children }) => (
		<Text fontSize='md' fontWeight='bold' mb={2}>
			{children}
		</Text>
	),
	h4: ({ children }) => (
		<Text fontWeight='bold' mb={2}>
			{children}
		</Text>
	),
	h5: ({ children }) => (
		<Text fontSize='xs' fontWeight='bold' mb={2}>
			{children}
		</Text>
	),
	h6: ({ children }) => (
		<Text fontSize='xs' fontWeight='bold' mb={2}>
			{children}
		</Text>
	),
	ul: ({ children }) => (
		<UnorderedList spacing={2} pl={4} mb={2}>
			<Text>{children}</Text>
		</UnorderedList>
	),
	ol: ({ children }) => (
		<OrderedList spacing={2} pl={4} mb={2}>
			<Text>{children}</Text>
		</OrderedList>
	),
	li: ({ children }) => <ListItem>{children}</ListItem>,
	pre: ({ children }) => (
		<Box
			as='pre'
			mt={4}
			mb={4}
			p={4}
			bg='gray.100'
			borderRadius='md'
			overflowX='auto'
		>
			{children}
		</Box>
	),
	a: ({ href, children }) => {
		const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.webp', '.svg']
		const videoExtensions = ['.mp4', '.webm', '.ogg']

		const isImage = imageExtensions.some((ext) =>
			href?.toLowerCase().endsWith(ext)
		)
		const isVideo = videoExtensions.some((ext) =>
			href?.toLowerCase().endsWith(ext)
		)

		if (isImage) {
			return <Image src={href} alt={children as string} maxW='100%' h='auto' />
		} else if (isVideo) {
			return (
				<video controls style={{ maxWidth: '100%', height: 'auto' }}>
					<source src={href} />
					Your browser does not support the video tag.
				</video>
			)
		} else {
			return <Hyperlink>{children as string}</Hyperlink>
		}
	},
	img: ({ src, alt }) => <Image src={src} alt={alt} maxW='100%' h='auto' />
}

export const MarkdownContent: FC<MarkdownContentProps> = ({
	content,
	fontSize = ['sm', 'md']
}) => {
	return (
		<Box fontSize={fontSize}>
			<ReactMarkdown
				remarkPlugins={[remarkGfm]}
				components={MarkdownComponents}
			>
				{content}
			</ReactMarkdown>
		</Box>
	)
}
