import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
	Button,
	Flex,
	Image,
	Text,
	Textarea,
	useBreakpointValue
} from '@chakra-ui/react'

import useSocket from 'config/socket/useSocket'

import { useStringToColor } from 'modules/common/hooks'
import { divergencyPointChannel } from 'modules/map/modules/point/divergency_point_channel'
import { useDivergenceStore } from 'modules/map/store/divergence-store'

import useCommentsStore from '../../store/use-comments-store'
import { AnonymAvatar } from '../view-divergence-point/anonym-mode/anonym-avatar'
import { validText } from './answer-question'
import SendIcon from './send.svg'

type Props = {
	commentsId: string
	userName: string
	userId: string
	commentsQuestionId: string
	changeVisibility?: () => void
	journeyId: string
	userUrl?: string
}
// TODO: refactor this component!!
export const InputComment: React.FC<Props> = ({
	commentsId,
	userName,
	commentsQuestionId,
	changeVisibility,
	journeyId,
	userId,
	userUrl
}) => {
	const { t } = useTranslation()
	const [inputComment, setInputComment] = useState('')
	const boxEditable = useRef<HTMLDivElement | null>(null)

	const bg = useStringToColor({ text: userName }, [500])[1]
	const isMobile = useBreakpointValue({ base: true, md: false })
	const { emit } = useSocket(divergencyPointChannel({ projectId: journeyId }))
	const { addReply, addReplyState, isLoadingSendReply } = useCommentsStore()
	const { isAnonym } = useDivergenceStore()

	const textAreaRef = useRef<HTMLTextAreaElement>(null)

	const resetTextAreaHeight = () => {
		if (textAreaRef && textAreaRef.current) {
			textAreaRef.current.style.height = 'auto'
		}
	}

	const handleKeyUp: React.KeyboardEventHandler<HTMLTextAreaElement> = (
		event
	) => {
		const textarea = event.currentTarget

		const maxScrollHeight = 0.2 * window.innerHeight

		textarea.style.height = 'auto'

		const currentScrollHeight = textarea.scrollHeight

		if (currentScrollHeight > maxScrollHeight) {
			textarea.style.overflowY = 'scroll'
			textarea.style.height = `${maxScrollHeight}px`
		} else {
			textarea.style.height = `${currentScrollHeight}px`
		}
	}

	const sendComment = async () => {
		const reply = await addReply(
			commentsQuestionId,
			inputComment.trim(),
			commentsId
		)
		addReplyState(reply)
		const replyInfo = {
			question_id: reply.question_id,
			parent_id: reply.parent_id,
			id: reply.id
		}
		emit('ADD', replyInfo)
		setInputComment('')
		if (boxEditable && boxEditable.current) {
			boxEditable.current.innerText = ''
		}
		if (changeVisibility) {
			changeVisibility()
		}
	}

	const handleSendCommentClick = async () => {
		await sendComment()
		resetTextAreaHeight()
	}

	const onChangeHandler = ({
		target
	}: React.ChangeEvent<HTMLTextAreaElement>) => {
		if (target.value.length > 3000) {
			return
		}
		setInputComment(target.value)
	}

	return (
		<Flex w='full' mt='4' justifyContent='space-around'>
			<AnonymAvatar
				isAnonym={isAnonym}
				avatarUrl={userUrl}
				name={userName}
				backgroundColor={bg}
			/>
			<Textarea
				ref={textAreaRef}
				onChange={onChangeHandler}
				onKeyUp={isMobile ? handleKeyUp : undefined}
				flex={1}
				border='none'
				outline='none'
				sx={{ '&:empty::before': { content: 'attr(data-placeholder)' } }}
				data-placeholder={t('divergencePoint:write_comment')}
				maxW='65%'
				mr={2}
				fontSize='md'
				ml={2}
				backgroundColor='gray.100'
				borderRadius='0.5rem'
				boxShadow='0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)'
				p='3'
				rows={1}
				value={inputComment}
			/>
			{!isMobile && (
				<Button
					onClick={handleSendCommentClick}
					backgroundColor={
						validText(inputComment)
							? 'evaluation_answer_comment_card_send_button'
							: 'evaluation_answer_comment_card_send_button_disabled'
					}
					borderRadius='18rem'
					size='md'
					w='6rem'
					_hover={{ backgroundColor: 'none' }}
					alignItems='center'
					justifyContent='center'
					isDisabled={!validText(inputComment)}
					isLoading={isLoadingSendReply}
				>
					<Text color='#fff' fontWeight='600' fontSize='sm'>
						{t('divergencePoint:send')}
					</Text>
					<Image ml={1} src={SendIcon} />
				</Button>
			)}
			{isMobile && (
				<Button
					onClick={handleSendCommentClick}
					backgroundColor={
						validText(inputComment)
							? 'evaluation_answer_comment_card_send_button'
							: 'evaluation_answer_comment_card_send_button_disabled'
					}
					borderRadius='50%'
					size='md'
					w='2rem'
					alignItems='center'
					justifyContent='center'
					isDisabled={!validText(inputComment)}
					isLoading={isLoadingSendReply}
				>
					<Image src={SendIcon} maxW='none' p={0} />
				</Button>
			)}
		</Flex>
	)
}
