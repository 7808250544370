import { useEffect } from 'react'

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { sessionManager } from 'config/session-manager'
import { checkIsLocalhost, setHeaderAuth } from 'data/helpers/local-auth'
import jwtDecode from 'jwt-decode'
import qs from 'qs'

export const baseURL = import.meta.env.VITE_API_URL

const httpClient = axios.create({
	baseURL,
	withCredentials: true
})

const updateToken = async () => {
	return await axios
		.request({
			url: `${baseURL}/users/v1/auth/refresh`,
			method: 'POST',
			withCredentials: true
		})
		.then((res) => {
			const token = jwtDecode<{ exp: number }>(res.data.access_token)
			sessionManager.updateSession(token.exp)
		})
}

httpClient.interceptors.request.use(async (request) => {
	const skipAuth = request.url?.match(/auth|signup|password-recovery/)

	if (skipAuth) {
		return request
	}

	if (!sessionManager.hasSession()) {
		const previousURL = window.location.pathname
		sessionStorage.setItem('previousURL', previousURL)

		window.location.replace(`/signin`)
	}

	if (sessionManager.hasSession() && sessionManager.isExpired()) {
		await updateToken()
	}

	const isLocalhost = checkIsLocalhost()
	if (isLocalhost) setHeaderAuth(request)

	return request
})

const fetch = <T>(requestConfig: AxiosRequestConfig) => {
	return httpClient.request<unknown, AxiosResponse<T>>({
		...requestConfig,
		withCredentials: true,
		paramsSerializer: (params) =>
			qs.stringify(params, { arrayFormat: 'repeat' })
	})
}

type ResponseParams = {
	onSuccess?: (response: AxiosResponse) => AxiosResponse
	onError?: (error: AxiosError) => void
}

export function useResponseInterceptor({ onSuccess, onError }: ResponseParams) {
	useEffect(() => {
		httpClient.interceptors.response.use(onSuccess, onError)
	}, [])
}

export default fetch
