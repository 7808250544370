import { fetch, Tokens } from 'config'
import { sessionManager } from 'config/session-manager'
import { checkIsLocalhost } from 'data/helpers/local-auth'
import jwtDecode from 'jwt-decode'

import { InvalidCredentialsError } from 'modules/login/errors'
import { AuthenticationParams, TokenModel } from 'modules/login/types'

const auth = async ({ email, password }: AuthenticationParams) => {
	return fetch<TokenModel>({
		url: '/users/v1/auth/signin',
		method: 'POST',
		data: {
			username: email,
			password
		}
	})
		.then((res) => {
			const token = jwtDecode<{ exp: number }>(res.data.access_token)

			const isLocalhost = checkIsLocalhost()
			if (isLocalhost) {
				Tokens.set(res.data)
			}

			sessionManager.startSession(token.exp)
			return res.data
		})
		.catch(() => {
			throw new InvalidCredentialsError()
		})
}

const logout = async () => {
	return fetch({
		url: '/users/v1/auth/logout',
		method: 'POST'
	}).then(() => {
		sessionManager.endSession()
	})
}

const AuthenticationApi = {
	auth,
	logout
}

export default AuthenticationApi
