import defaultCustomColors from '../default/colors'

const efesimpactCustomColors = {
	...defaultCustomColors,

	extra_color_scheme_button: {
		500: '#003399',
		700: '#003399'
	},

	secondary_color_scheme_button: {
		700: '#18BAA8'
	},

	switch_scheme_color_checked: {
		500: '#003399'
	},

	radio_color: {
		500: '#003399'
	}
}

export default efesimpactCustomColors
